import { css } from 'linaria';
import intents from './intents.config.js';
import feedbackExpressions from '@ux/intents/lib/feedback-expressions';

function createTextVariant(type) {
  if (!intents[`uxText${type}`]) return;
  return `
    ${['&.ux-text-feedback', type.toLowerCase()].join('-')} {
      color: ${intents[`uxText${type}`].foregroundColor};
    }
  `;
}

export default css`
  :global() {
    .ux-text {

      /* Calculations */
      --uxText--fontSize0: ${intents.uxText.fontSize};

      --uxText--fontSize-1: calc(var(--uxText--fontSize0) ${intents.uxText.scaleOperationInverse.fontSize} ${intents.uxText.scaleFactor.fontSize});
      --uxText--fontSize-2: calc(var(--uxText--fontSize-1) ${intents.uxText.scaleOperationInverse.fontSize} ${intents.uxText.scaleFactor.fontSize});

      --uxText--fontSize1: calc(var(--uxText--fontSize0) ${intents.uxText.scaleOperation.fontSize} ${intents.uxText.scaleFactor.fontSize});
      --uxText--fontSize2: calc(var(--uxText--fontSize1) ${intents.uxText.scaleOperation.fontSize} ${intents.uxText.scaleFactor.fontSize});
      --uxText--fontSize3: calc(var(--uxText--fontSize2) ${intents.uxText.scaleOperation.fontSize} ${intents.uxText.scaleFactor.fontSize});
      --uxText--fontSize4: calc(var(--uxText--fontSize3) ${intents.uxText.scaleOperation.fontSize} ${intents.uxText.scaleFactor.fontSize});
      --uxText--fontSize5: calc(var(--uxText--fontSize4) ${intents.uxText.scaleOperation.fontSize} ${intents.uxText.scaleFactor.fontSize});

      --uxText--lineHeight: calc(${intents.uxText.lineHeight} ${intents.uxText.scaleOperation.lineHeight} ${intents.uxText.scaleFactor.lineHeight});

      font-size: var(--uxText--fontSize0);
      line-height: var(--uxText--lineHeight);
      font-family: ${intents.uxText.fontFamily};
      font-weight: ${intents.uxText.fontWeight};

      &.ux-text-title {
        --uxText--fontSize0: ${intents.uxText.title.fontSize};
        --uxText--lineHeight: ${intents.uxText.title.lineHeight};
        font-family: ${intents.uxText.title.fontFamily};
        font-weight: ${intents.uxText.title.fontWeight};
      }

      &.ux-text-heading {
        --uxText--fontSize0: ${intents.uxText.heading.fontSize};
        --uxText--lineHeight: ${intents.uxText.heading.lineHeight};
        font-family: ${intents.uxText.heading.fontFamily};
        font-weight: ${intents.uxText.heading.fontWeight};
      }

      &.ux-text-paragraph {
        --uxText--fontSize0: ${intents.uxText.paragraph.fontSize};
        --uxText--lineHeight: ${intents.uxText.paragraph.lineHeight};
        font-family: ${intents.uxText.paragraph.fontFamily};
        font-weight: ${intents.uxText.paragraph.fontWeight};
      }

      &.ux-text-action {
        --uxText--fontSize0: ${intents.uxText.action.fontSize};
        --uxText--lineHeight: ${intents.uxText.action.lineHeight};
        font-family: ${intents.uxText.action.fontFamily};
        font-weight: ${intents.uxText.action.fontWeight};
      }

      &.ux-text-input {
        --uxText--fontSize0: ${intents.uxText.input.fontSize};
        --uxText--lineHeight: ${intents.uxText.input.lineHeight};
        font-family: ${intents.uxText.input.fontFamily};
        font-weight: ${intents.uxText.input.fontWeight};
      }

      &.ux-text-label {
        --uxText--fontSize: ${intents.uxText.label.fontSize};
        --uxText--lineHeight: ${intents.uxText.label.lineHeight};
        font-family: ${intents.uxText.label.fontFamily};
        font-weight: ${intents.uxText.label.fontWeight};
      }

      &.ux-text-caption {
        --uxText--fontSize0: ${intents.uxText.caption.fontSize};
        --uxText--lineHeight: ${intents.uxText.caption.lineHeight};
        font-family: ${intents.uxText.caption.fontFamily};
        font-weight: ${intents.uxText.caption.fontWeight};
      }

      /** sizing (modular scale) */

      &.ux-text-size0 {
        font-size: var(--uxText--fontSize0);
      }

      &.ux-text-size1 {
        font-size: var(--uxText--fontSize1);
      }

      &.ux-text-size2 {
        font-size: var(--uxText--fontSize2);
      }

      &.ux-text-size3 {
        font-size: var(--uxText--fontSize3);
      }

      &.ux-text-size4 {
        font-size: var(--uxText--fontSize4);
      }

      &.ux-text-size5 {
        font-size: var(--uxText--fontSize5);
      }

      &.ux-text-size-1 {
        font-size: var(--uxText--fontSize-1);
      }

      &.ux-text-size-2 {
        font-size: var(--uxText--fontSize-2);
      }

      ${Object.values(feedbackExpressions || {}).map(createTextVariant).filter(Boolean).join('\n')}
    }
  }
`;
