import { css } from 'linaria';
import intents from './intents.config.js';

export default css`
@import '~@ux/icon/chevron-down/index.css';
@import '~@ux/icon/link-arrow/index.css';
@import '~@ux/icon/window-new/index.css';

  :global() {
    .ux-button {
      font-family: ${intents.uxButton.fontFamily};
      font-size: ${intents.uxButton.fontSize};
      line-height: ${intents.uxButton.lineHeight};
      color: ${intents.uxButton.foregroundColor};
      font-weight: inherit;
      background: transparent;
      margin: 0;
      border: 0;
      padding: 0;
      text-decoration: ${intents.ux.navigate.textDecoration};
      cursor: pointer;

      /** Ensure only the button triggers events, specific to button usage only */
      &:not([href]) > * {
        pointer-events: none;
      }

      &:focus,
      &.focus {
        outline: var(--uxp-focus-visible-outline, none);
      }

      &:hover,
      &:active {
        color: ${intents.uxButtonHovered.foregroundColor};
        background-color: transparent;
      }

      &[href] {
        color: ${intents.uxButtonLink.foregroundColor};
        &:hover,
        &:active {
          color: ${intents.uxButtonLinkHovered.foregroundColor};
        }
      }

      &[disabled],
      &[disabled]:hover {
        opacity: var(--uxButtonDisabled--opacity, .4);
        cursor: not-allowed;
      }

      &[aria-hidden="true"] {
        visibility: hidden;
      }

      /* Gaps between parts, replace with gap property on parent (Safari) */
      & > :not(:last-child) {
        margin-inline-end: .5em;
      }

      &.ux-button-small {
        font-size: calc(${intents.uxButton.fontSize} * .835);
      }

      .ux-button-icon,
      .ux-button-accessory {
        vertical-align: middle;
        flex-shrink: 0;
        font-size: calc(20em / 24); /* 20px (24px = 1.5em) */
      }

      .ux-button-accessory {
        margin-inline-start: auto;
      }

      /* All common padded buttons */
      &:not(.ux-button-inline) {
        font-weight: ${intents.uxButton.fontWeight};
        border: ${intents.uxButton.borderWidth} solid ${intents.uxButton.borderColor};
        background-color: ${intents.uxButton.backgroundColor};
        border-radius: ${intents.uxButton.borderRadius};
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        min-width: min-content;
        white-space: nowrap;
        padding: calc(${intents.ux.box.density} * 2) calc(${intents.ux.box.density} * 4);
        text-decoration: none;
        transition: .15s ease-in-out;
        vertical-align: middle;

        &.ux-button-square {
          padding: calc(${intents.ux.box.density} * 2.5); /* ~10px padding */
        }

        &.ux-button-small {
          padding: calc(${intents.ux.box.density} * 1) calc(${intents.ux.box.density} * 3);
        }

        &.ux-button-small.ux-button-square {
          padding: calc(${intents.ux.box.density} * 1);
        }

        ${createExpressionStyles('', { withBaseStyle: false, withInteraction: true })}
      }

      /* Stateless buttons should not show most interactions */
      &.ux-button-stateless {
        ${createExpressionStyles('Stateless', { withBaseStyle: true, withInteraction: false })}

        @supports (backdrop-filter: contrast(.8)) {
          &:hover {
            backdrop-filter: contrast(.8);
          }
        }
      }

      &.ux-button-primary {
        ${createExpressionStyles('Primary', { withBaseStyle: true, withInteraction: true })}
      }

      &.ux-button-secondary {
        ${createExpressionStyles('Secondary', { withBaseStyle: true, withInteraction: true })}
      }

      &.ux-button-critical {
        ${createExpressionStyles('Critical', { withBaseStyle: true, withInteraction: true })}
      }

      &.ux-button-control {
        border-radius: 0; /* Expected to take the radius of the form control container */
        ${createExpressionStyles('Control', { withBaseStyle: true, withInteraction: true })}
      }
    }
  }
`;

function createExpressionStyles(expression, { withBaseStyle, withInteraction } = {}) {
  const category = `uxButton${expression}`;
  const hovered = withInteraction ? `${category}Hovered` : category;
  const chosen = withInteraction ? `${category}Chosen` : category;

  return `
    ${ withBaseStyle ? getDefaultStyles(category) : '' }
    ${ getHoverStyles(hovered) }
    ${ getChosenStyles(chosen) }
  `;
}

const baseValues = {
  backgroundColor: 'transparent',
  foregroundColor: 'inherit',
  borderColor: 'transparent'
};

function getDefaultStyles(category) {
  const { backgroundColor, foregroundColor, borderColor } = intents[category] || baseValues;

  return `
    background-color: ${backgroundColor};
    color: ${foregroundColor};
    border-color: ${borderColor};
  `;
}

function getHoverStyles(hovered) {
  const { backgroundColor, foregroundColor, borderColor } = intents[hovered] || baseValues;
  return `
    &:not([disabled]):hover {
      background-color: ${backgroundColor};
      color: ${foregroundColor};
      border-color: ${borderColor};
    }
  `;
}

function getChosenStyles(chosen) {
  const { backgroundColor, foregroundColor, borderColor } = intents[chosen] || baseValues;
  return `
    &:not([disabled]):active,
    &:not([disabled])[aria-checked="true"],
    &:not([disabled])[aria-expanded="true"],
    &:not([disabled])[aria-selected="true"] {
      background-color: ${backgroundColor};
      color: ${foregroundColor};
      border-color: ${borderColor};
    }
  `;
}
