import { css } from 'linaria';
import intents from './intents.config';

export default css`
  :global() {
    .ux-disrupt-backdrop {
      box-sizing: border-box;
      position: absolute;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      padding: calc(${intents.ux.box.density} * 4);
      max-width: 100vw;
      max-height: 100vh;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: ${intents.ux.boxLowContrastOverlay.backgroundColor};
      backdrop-filter: blur(${intents.uxDisrupt.backdropBlur}) Saturate(${intents.uxDisrupt.backdropSaturate});
    }

    .ux-disrupt-content {
      /* Transform is used to reposition disrupt content on content resize.
      It fixes drop-shadow issues when content resizes in Safari. */
      transform: translateZ(0);
      visibility: hidden;
      margin: auto;
      max-width: 80rem;
      position: relative;
      filter: drop-shadow(0 2px 4px rgba(0,0,0,.3));

      &.ux-disrupt--stretch {
        width: 100%;
      }
    }

    [data-inert], :host([data-inert]) {
      position: relative;
      overflow: hidden;

      &:focus {
        outline: 0;
      }

      .ux-disrupt-content {
        visibility: visible;
      }
    }
  }
`;
