import { css } from 'linaria';
import intents from './intents.config.js';

export default css`
:global() {
  .ux-toggle {
    --uxToggle--widthFactor: 10;
    --uxToggle--heightFactor: 6;
    --uxToggle-knob--dimensionFactor: 4;
    --uxToggle-knob--offset: .3125rem;

    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    margin-right: calc(${intents.ux.box.density} * 4);
    line-height: 1;

    .ux-toggle-container {
      position: relative;
      pointer-events: none;
    }

    .ux-toggle-bg {
      position: relative;
      display: inline-flex;
      width: calc(var(--uxToggle--widthFactor) * var(--uxToggle-knob--offset));
      height: calc(var(--uxToggle--heightFactor) * var(--uxToggle-knob--offset));
      background-color: ${intents.uxToggle.body.backgroundColor};
      border: 2px solid ${intents.uxToggle.body.borderColor};
      transition: background 100ms ease-out, border 100ms ease-out;
      border-radius: 1rem;
    }

    .ux-toggle-handle {
      position: absolute;
      border-radius: 50%;
      width: calc(var(--uxToggle-knob--dimensionFactor) * var(--uxToggle-knob--offset));
      height: calc(var(--uxToggle-knob--dimensionFactor) * var(--uxToggle-knob--offset));
      top: 0;
      left: 0;
      margin: var(--uxToggle-knob--offset);
      background-color: ${intents.uxToggle.knob.backgroundColor};
      transition: left 100ms ease-in, border 100ms ease-in;
    }

    .ux-toggle-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      &:checked ~ .ux-toggle-container .ux-toggle-bg {
        background-color: ${intents.uxToggleChosen.body.backgroundColor};
        border-color: ${intents.uxToggleChosen.body.borderColor};
      }

      &:checked ~ .ux-toggle-container .ux-toggle-handle {
        background-color: ${intents.uxToggleChosen.knob.backgroundColor};
        left: calc(var(--uxToggle-knob--dimensionFactor) * var(--uxToggle-knob--offset));
      }
    }

    &.ux-toggle-small {
      --uxToggle-knob--offset: .25rem;

      .ux-toggle-message {
        font-size: calc(${intents.uxToggle.label.fontSize} * .875);
      }
    }

    .ux-toggle-message {
      font-family: ${intents.uxToggle.label.fontFamily};
      font-size: ${intents.uxToggle.label.fontSize};
      font-weight: ${intents.uxToggle.label.fontWeight};
      line-height: ${intents.uxToggle.label.lineHeight};
      display: inline-flex;
      align-items: center;
      margin-left: calc(${intents.ux.box.density} * 4);
      white-space: nowrap;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: .4;
      &.ux-toggle-message {
        opacity: .4;
      }
    }
  }
}
`;
