import { css } from 'linaria';
import Intents from '@ux/intents';
import '@ux/disrupt/src/styles';
import '@ux/dialog/src/styles';

const intents = new Intents();

export default css`
  :global() {
    .ux-modal[aria-modal] {
      color: ${intents.ux.box.foregroundColor};
      background-color: ${intents.ux.box.backgroundColor};
      border-color: ${intents.ux.box.borderColor};
      border-width: ${intents.ux.box.borderWidth};
      border-radius: ${intents.ux.box.borderRadius};
      padding: calc(${intents.ux.box.density} * 8);

      @media (max-width: 500px) {
        padding: calc(${intents.ux.box.density} * 4);
      }
    }
  }
`;
