import { css } from 'linaria';
import Intents from '@ux/intents';
import '@ux/button/src/styles';
import '@ux/text/src/styles';
import '@ux/sibling-set/src/styles';

const intents = new Intents();

export default css`
  :global() {

    @import '~@ux/icon/x/index.css';
    @import '~@ux/icon/checkmark/index.css';
    @import '~@ux/icon/information/index.css';
    @import '~@ux/icon/comment/index.css';
    @import '~@ux/icon/alert/index.css';
    @import '~@ux/icon/help/index.css';
    @import '~@ux/icon/star/index.css';

    .ux-dialog-shell {
      border: inherit;
      background: inherit;
      color: inherit;
      border-radius: inherit;
      display: inline-flex;
      gap: calc(${intents.ux.box.density} * 2);
      box-sizing: border-box;
      position: relative;

      &.ux-dialog--stretch {
        display: flex;
        min-width: 100%;
      }
    }

    .ux-dialog-icon {
      display: inline-flex;
      flex-shrink: 0;
      align-self: baseline;
    }

    .ux-dialog-details {
      flex: 1;
    }

    .ux-dialog-float {
      float: right;
      width: 2rem;
      height: 2rem;
      > * {
        position: absolute;
        top: 0;
        right: 0;
        color: currentColor;
      }
    }

    .ux-dialog-title {
      overflow-x: auto;
      overflow-y: clip;
      margin-block-end: calc(${intents.ux.box.density} * 2);
      > * {
        margin: 0;
      }
    }

    .ux-dialog-content {
      overflow: visible;
    }

    .ux-dialog-actions {
      margin-block-start: calc(${intents.ux.box.density} * 2);
    }

    .ux-dialog--contrast .ux-dialog-actions {
      filter: grayscale(1);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
`;
