import { css } from 'linaria';
import Intents from '@ux/intents';
import '@ux/text/src/styles';

const intents = new Intents();

export default css`
  :global() {
    .ux-sibling-set {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;

      &.ux-sibling-set--stretch {
        display: flex;
        align-items: stretch;

        .ux-sibling-set-children > * {
          justify-content: flex-start;
        }
      }

      &.ux-sibling-set--wrap {
        flex-wrap: wrap;
      }

      > hr {
        width: 100%;
        margin: 0;
        border: 0;
        border-top: 1px solid;
      }

      .ux-sibling-set-label {
        margin-block-end: calc(${intents.ux.box.density} * 2);
      }

      .ux-sibling-set-children {
        flex: 1;
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
        flex-wrap: inherit;
        gap: var(--ux-siblingset-gap, 0);
      }

      &.ux-sibling-set-gap-sm {
        --ux-siblingset-gap: calc(${intents.ux.box.density} * 2);
      }

      &.ux-sibling-set-gap-md {
        --ux-siblingset-gap: calc(${intents.ux.box.density} * 4);
      }

      &.ux-sibling-set-gap-lg {
        --ux-siblingset-gap: calc(${intents.ux.box.density} * 6);
      }

      &.ux-sibling-set-horizontal .ux-sibling-set-children {
        flex-direction: row;
      }

      &.ux-sibling-set-vertical .ux-sibling-set-children {
        flex-direction: column;
      }

      .ux-text-caption {
        margin: calc(${intents.ux.box.density} * 1) 0 0;
      }
    }
  }
`;
